import React, { Component } from "react"
import { PulseLoader } from "react-spinners"

class SpinnerLoading extends Component {
  state = {}
  render() {
    return (
      <div className="col-12 c">
        <PulseLoader
          sizeUnit={"px"}
          size={15}
          color={process.env.SPINNER_LOADING_COLOR}
          loading={true}
        />
      </div>
    )
  }
}

export default SpinnerLoading
